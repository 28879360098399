.project-detail {
    position: relative;
    padding: 120px 0;
    margin-bottom: 50px;
}

.project-detail .inner-container {
    position: relative;
    max-width: 970px;
    margin: 0 auto;
}

.project-detail .upper-box {
    position: relative;
    border: 3px solid var(--white-color);
    padding: 23px 0px 0px 45px;
    margin-top: 40px;
}

.project-detail .project-info {
    position: relative;
    margin: 0;
    list-style-type: none;
}

.project-detail .project-info li {
    position: relative;
    float: left;
    font-size: 14px;
    line-height: 28px;
    color: var(--black-shade7);
    font-weight: 400;
    margin-bottom: 23px;
    padding-left: 40px;
    width: 33.3333%;
    padding-right: 20px;
}

.project-detail .project-info li .a {
    color: var(--black-shade7);
    display: inline-block;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.project-detail .project-info li .a:hover {
    color: var(--primary-color);
}

.project-detail .project-info li .icon {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 20px;
    line-height: 28px;
    color: var(--primary-color);
}

.project-detail .project-info li strong {
    color: var(--black-shade2);
    font-family: "Roboto", sans-serif;
}

.project-detail .title-box {
    position: relative;
}

.project-detail .title-box h2 {
    display: block;
    font-size: 30px;
    line-height: 1.2em;
    color: var(--black-shade2);
    font-weight: 700;
    margin-bottom: 25px;
}

.project-detail .title-box .text {
    position: relative;
    display: block;
    font-size: 18px;
    line-height: 24px;
    color: var(--black-shade7);
    font-weight: 400;
    margin-bottom: 35px;
}

/* Project Carousel */

.project-detail .single-item-carousel .image-box {
    position: relative;
}

.project-detail .single-item-carousel .image {
    position: relative;
    margin-bottom: 0;
}

.project-detail .single-item-carousel .image img {
    display: block;
    width: 100%;
    height: auto;
}

.project-detail .single-item-carousel .owl-nav {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    margin-top: -25px;
}

.project-detail .single-item-carousel .owl-next,
.project-detail .single-item-carousel .owl-prev {
    position: absolute;
    left: -100px;
    height: 50px;
    width: 50px;
    background-color: #33373f;
    border-radius: 50%;
    text-align: center;
    font-size: 24px;
    line-height: 50px;
    color: var(--bg-color);
    font-weight: 400;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.project-detail .single-item-carousel .owl-next {
    left: auto;
    right: -100px;
}

.project-detail .single-item-carousel .owl-next:hover,
.project-detail .single-item-carousel .owl-prev:hover {
    background-color: var(--primary-color);
    color: var(--bg-color);
}

/* Lower Content */

.project-detail .lower-content {
    position: relative;
    padding-top: 35px;
}

.project-detail .lower-content h3 {
    font-size: 24px;
    line-height: 1.2em;
    color: var(--black-shade2);
    font-weight: 700;
    margin-bottom: 20px
}

.project-detail .lower-content p {
    font-size: 14px;
    line-height: 27px;
    color: var(--black-shade7);
    margin-bottom: 30px;
}

.project-detail .two-column {
    position: relative;
    margin-top: 40px;
}

.project-detail .two-column .column {
    position: relative;
    margin-bottom: 40px;
}


.project-detail .video-box {
    position: relative;
    margin-bottom: 50px;
}

.video-box {
    position: relative;
}

.video-box .image {
    position: relative;
    margin-bottom: 0;
}

.video-box .image img {
    display: block;
    width: 100%;
}

.video-box .link {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

.video-box .link .icon {
    position: absolute;
    display: inline-block;
    left: 50%;
    top: 50%;
    height: 60px;
    width: 60px;
    text-align: center;
    line-height: 60px;
    margin-left: -30px;
    margin-top: -30px;
    color: var(--primary-color);
    font-size: 24px;
    border-radius: 50%;
    background-color: var(--bg-color);
    padding-left: 5px;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.video-box .link:hover .icon {
    background-color: var(--primary-color);
    color: var(--bg-color);
}

.project-detail .project-files {
    position: relative;
    padding: 35px 35px 10px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.19);
    border-radius: 20px;
}

.project-detail .project-files h4 {
    display: block;
    font-size: 18px;
    line-height: 1.2em;
    color: var(--black-shade2);
    font-weight: 700;
    margin-bottom: 30px;
}

.project-detail .file-list {
    position: relative;
    list-style-type: none;
    margin: 0;
}

.project-detail .file-list li {
    position: relative;
    float: left;
    width: 50%;
    font-size: 14px;
    line-height: 20px;
    color: var(--black-shade7);
    font-weight: 400;
    margin-bottom: 20px;
}

.project-detail .file-list li .a {
    color: var(--black-shade7);
    padding-left: 40px;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.project-detail .file-list li .icon {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 18px;
    color: var(--primary-color);
}

.project-detail .file-list li .a:hover {
    color: var(--primary-color);
}

/* Load More Option */
.load-more-option {
    position: relative;
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 20px;
}

.load-more-option ul {
    position: relative;
    margin: 0;
    list-style-type: none;
}

.load-more-option li.prev .a,
.load-more-option li.next .a {
    position: relative;
    display: block;
    font-size: 18px;
    line-height: 30px;
    width: 140px;
    padding: 10px 20px;
    text-align: center;
    color: var(--bg-color);
    background-color: var(--black-shade2);
    text-transform: uppercase;
    border-radius: 0 30px 30px 0;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.load-more-option li.next .a {
    border-radius: 30px 0 0 30px;
}

.load-more-option li.prev .a:hover,
.load-more-option li.next .a:hover {
    background-color: #ff9600;
}

.load-more-option .load-more {
    position: relative;
    display: inline-block;
    font-size: 30px;
}

.load-more-option .load-more .a {
    font-size: 30px;
    line-height: 50px;
    color: var(--primary-color);
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.load-more-option .load-more .a:hover {
    color: var(--black-shade0);
}

@media screen and (max-width: 1000px) {
    .video-box {
        display: none;
    }

    .benefits {
        margin-left: 1rem;
    }

    .textile-logo {
        display: none;
    }
}

.benefits {
    margin-left: 1rem;
}

.textile-logo {
    vertical-align: middle;
    border-style: none;
    position: absolute;
    right: 0;
    top: -75px;
    scale: 0.43;
}

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

td,
th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

th.table_key {
    width: 15%;
}

th.table_value {
    width: 85%;
}

tr:nth-child(even) {
    background-color: #dddddd;
}

.return {
    display: block;
    text-align: end;
    font-size: 1rem;
    padding: 10px;
    /* position: absolute; */
    /* right: 0; */
}