.clients-section {
  position: relative;
  padding: 100px 0;
  background-color: #f5f5f5;
}

.clients-section .sponsors-outer {
  position: relative;
}

.clients-section .sponsors-carousel {
  position: relative;
  list-style-type: none;
  margin: 0;
}

.clients-section .slide-item {
  position: relative;
  list-style: none;
  margin: 0 10px;
}

.clients-section .image-box {
  position: relative;
  margin: 0;
  text-align: center;
}

.clients-section .image-box img {
  display: inline-block;
  max-width: 100%;
  width: auto;
  height: auto;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.clients-section .image-box:hover img {
  opacity: 0.5;
}

.clients-section .sponsors-carousel .owl-dots,
.clients-section .sponsors-carousel .owl-nav {
  display: none;
}