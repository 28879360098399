.preloader{ 
	position:fixed; 
	left:0px; 
	top:0px; 
	width:100%; 
	height:100%; 
	z-index:999999; 
	background-color:#ffffff; 
	background-position:center center; 
	background-repeat:no-repeat; 
	background-image:url(../../assets/images/icons/preloader.svg);
}
