.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  color: var(--bg-color);
  font-size: 30px;
  line-height: 55px;
  text-align: center;
  z-index: 100;
  cursor: pointer;
  background: var(--black-shade2);
  display: none;
  border-radius: 0;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.scroll-to-top:hover {
  background: var(--primary-color);
  color: var(--bg-color);
}

.show {
  display: block !important;
}

.hide {
  display: none !important;
}
