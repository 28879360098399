.contact-map-section {
    position: relative;
    height: 500px;
}

.contact-map-section .map-outer {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #343434;
}

.contact-map-section .map-canvas {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

.map-canvas {
    position: relative;
}

.map-data {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.8em;
}

.map-data .a {
    display: block;
}

.map-data h6 {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 5px;
    color: #121212;
}
