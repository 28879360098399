.single-item-carousel {
  display: flex;
}

.news-section {
  position: relative;
  padding: 95px 0 60px;
}

.news-block {
  position: relative;
  margin-bottom: 40px;
}

.news-block .inner-box {
  position: relative;
  padding-right: 40px;
}

.news-block .image-box {
  position: relative;
}

.news-block .image-box .image {
  position: relative;
  margin-bottom: 0;
  overflow: hidden;
}

.news-block .image-box .icon-box {
  position: absolute;
  left: 0;
  top: 0;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  color: var(--bg-color);
  font-weight: 400;
  background-color: #252525;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.news-block .inner-box:hover .icon-box {
  background-color: var(--primary-color);
}

.news-block .image-box .owl-nav {
  position: absolute;
  left: 0;
  width: 100%;
  top: 50%;
  margin-top: -45px;
}

.news-block .image-box .owl-nav .owl-next,
.news-block .image-box .owl-nav .owl-prev {
  position: absolute;
  width: 25px;
  height: 90px;
  text-align: center;
  line-height: 90px;
  font-size: 17px;
  color: var(--bg-color);
  font-weight: 400;
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.news-block .image-box .owl-nav .owl-next {
  left: auto;
  right: 0;
}

.news-block .image-box .owl-nav .owl-next:hover,
.news-block .image-box .owl-nav .owl-prev:hover {
  color: var(--black-shade2);
  background-color: rgba(255, 255, 255, 0.8);
}

.news-block .image-box .image img {
  display: block;
  width: 100%;
  height: auto;
}

.news-block .lower-content {
  position: relative;
  right: -40px;
  margin-top: -40px;
  padding: 25px 25px 30px;
  background-color: var(--bg-color);
  box-shadow: 6px 8px 21px rgba(0, 0, 0, 0.15);
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  z-index: 9;
}

.news-block .inner-box:hover .lower-content {
  background-color: var(--primary-color);
}

.news-block .lower-content .post-info {
  position: relative;
  margin-bottom: 10px;
}

.news-block .lower-content .post-info li {
  position: relative;
  display: inline-block;
  padding-right: 15px;
  margin-right: 15px;
  font-size: 14px;
  line-height: 28px;
  color: var(--black-shade7);
  font-weight: 400;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.news-block .inner-box:hover .lower-content .post-info li:before,
.news-block .inner-box:hover .lower-content .post-info li {
  color: var(--bg-color);
}

.news-block .lower-content .post-info li .a {
  color: var(--black-shade7);
}

.news-block .lower-content .post-info li:before {
  position: absolute;
  right: -3px;
  top: 0;
  font-size: 14px;
  line-height: 28px;
  color: var(--black-shade7);
  font-weight: 400;
  content: "|";
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.news-block .lower-content .post-info li:last-child:before {
  display: none;
}

.news-block .lower-content h3 {
  position: relative;
  display: block;
  font-size: 20px;
  line-height: 1.2em;
  color: var(--black-shade2);
  font-weight: 700;
  margin-bottom: 20px;
}

.news-block .lower-content h3 .a {
  color: var(--black-shade2);
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.news-block .inner-box:hover .lower-content h3 .a {
  color: var(--bg-color);
}

.news-block .lower-content .text {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 30px;
  color: var(--black-shade7);
  font-weight: 400;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.news-block .inner-box:hover .lower-content .text {
  color: var(--bg-color);
}

.news-block .lower-content .link-box {
  position: relative;
}

.news-block .lower-content .link-box .a {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 25px;
  color: var(--primary-color);
  text-transform: uppercase;
  font-weight: 500;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.news-block .lower-content .link-box .a:hover {
  color: var(--black-shade2);
}

.news-block .lower-content .link-box .a i {
  margin-left: 5px;
}

.sidebar-page-container {
  position: relative;
  overflow: hidden;
  padding: 120px 0 70px;
}

.sidebar-page-container .sidebar-side,
.sidebar-page-container .content-side {
  position: relative;
  margin-bottom: 50px;
}

.sidebar-widget {
  position: relative;
  margin-bottom: 55px;
}

.sidebar-side .sidebar-widget:last-child {
  margin-bottom: 0;
}

.sidebar-page-container .sidebar-title {
  position: relative;
  margin-bottom: 35px;
}

.sidebar-page-container .sidebar-title h2 {
  position: relative;
  display: inline-block;
  font-size: 24px;
  line-height: 1.2em;
  color: var(--black-shade2);
  font-weight: 700;
  text-transform: capitalize;
}

.sidebar-page-container .sidebar-title .devider {
  position: relative;
  display: block;
  margin-top: 10px;
  width: 150px;
  text-align: center;
}

.sidebar-page-container .sidebar-title .devider:before {
  position: absolute;
  left: 0;
  top: 14px;
  height: 1px;
  width: 52px;
  background-color: var(--black-shade7);
  content: "";
}

.sidebar-page-container .sidebar-title .devider span {
  position: relative;
  display: inline-block;
  font-size: 22px;
  line-height: 30px;
  color: var(--black-shade7);
  font-weight: 400;
}

.blog-default {
  position: relative;
}

.news-section-three {
  position: relative;
  padding: 120px 0 80px;
}

.news-block-three {
  position: relative;
  padding-bottom: 50px;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 35px;
}

.news-block-three .inner-box {
  position: relative;
}

.news-block-three .inner-box h4 {
  position: relative;
  font-size: 24px;
  line-height: 1.2em;
  color: var(--black-shade2);
  font-weight: 700;
  margin-bottom: 5px;
}

.news-block-three .inner-box h4 .a {
  color: var(--black-shade2);
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.news-block-three .inner-box h4 a:hover {
  color: var(--primary-color);
}

.news-block-three .inner-box .info {
  position: relative;
  list-style-type: none;
  margin-bottom: 15px;
}

.news-block-three .inner-box .info li {
  position: relative;
  float: left;
  margin-right: 17px;
  padding-right: 17px;
  font-size: 14px;
  line-height: 50px;
  color: #777777;
  font-weight: 400;
}

.news-block-three .inner-box .info li:before {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 14px;
  line-height: 50px;
  color: #777777;
  content: "|";
}

.news-block-three .inner-box .info li:last-child:before {
  display: none;
}

.news-block-three .inner-box .info li i {
  position: relative;
  display: inline-block;
  font-size: 16px;
  color: #777777;
  margin-right: 7px;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.news-block-three .inner-box .info li .a {
  position: relative;
  display: inline-block;
  color: #777777;
  font-weight: 400;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.news-block-three .inner-box .info li:hover i,
.news-block-three .inner-box .info li a:hover {
  color: var(--primary-color);
}

.news-block-three .image-box {
  position: relative;
}

.news-block-three .image-box .icon-box {
  position: absolute;
  right: 0;
  bottom: 35px;
  height: 45px;
  width: 70px;
  line-height: 45px;
  text-align: center;
  font-size: 24px;
  color: var(--bg-color);
  font-weight: 400;
  background-color: var(--primary-color);
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.news-block-three .image-box .icon-box:before {
  position: absolute;
  bottom: 0;
  right: 100%;
  border-bottom: 45px solid var(--primary-color);
  border-left: 15px solid transparent;
  content: "";
}

.news-block-three .image-box .image {
  position: relative;
  margin-bottom: 0;
  overflow: hidden;
}

.news-block-three .image-box .image img {
  display: block;
  width: 100%;
  height: auto;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.news-block-three .inner-box:hover .image-box .image img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

.news-block-three .lower-content {
  position: relative;
  padding: 28px 0 0;
}

.news-block-three .lower-content .text {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 30px;
  color: #777777;
  font-weight: 400;
}

.news-block-three .lower-content .link-box {
  position: relative;
  margin-top: 20px;
}

.news-block-three .lower-content .link-box .a {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  padding: 14px 40px;
  color: var(--black-shade2);
  font-weight: 500;
  border: 1px solid var(--primary-color);
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.news-block-three .lower-content .link-box .a:hover {
  background-color: var(--primary-color);
  color: var(--bg-color);
}

.news-block-three .blockquote {
  position: relative;
  display: block;
  border: 2px solid #d7d7d7;
  padding: 50px 50px;
}

.news-block-three .blockquote:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: var(--black-shade0);
  content: "";
  opacity: .80;
}

.news-block-three .blockquote h2 {
  position: relative;
  display: block;
  font-size: 36px;
  line-height: 60px;
  color: var(--bg-color);
  font-weight: 500;
  font-family: 'Oswald', sans-serif;
  margin-bottom: 70px;
}

.news-block-three .blockquote h2 span {
  color: var(--primary-color);
  text-transform: uppercase;
}

.news-block-three .blockquote .author-info {
  position: relative;
  display: inline-block;
  text-align: right;
}

.news-block-three .blockquote .author {
  position: relative;
  display: block;
  line-height: 25px;
  color: var(--primary-color);
  font-size: 18px;
  text-decoration: underline;
  font-family: 'Oswald', sans-serif;
}

.news-block-three .blockquote .designation {
  position: relative;
  display: block;
  font-size: 12px;
  line-height: 1em;
  color: var(--bg-color);
  margin-top: 5px;
  font-weight: 400;
}

.news-block-three .single-item-carousel {
  position: relative;
}

.news-block-three .image-box .owl-nav {
  position: absolute;
  left: 0;
  width: 100%;
  top: 50%;
  margin-top: -60px;
}

.news-block-three .image-box .owl-nav .owl-next,
.news-block-three .image-box .owl-nav .owl-prev {
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  height: 120px;
  text-align: center;
  line-height: 120px;
  font-size: 18px;
  color: var(--black-shade2);
  font-weight: 400;
  background-color: rgba(255, 255, 255, .70);
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.news-block-three .image-box .owl-nav .owl-next {
  left: auto;
  right: 0;
}

.news-block-three .image-box .owl-nav .owl-next:hover,
.news-block-three .image-box .owl-nav .owl-prev:hover {
  background-color: rgba(0, 0, 0, .70);
  color: var(--bg-color);
}

.blog-default .styled-pagination {
  margin-top: 50px;
}

.blog-detail {
  position: relative;
}

.blog-detail .news-block-three {
  position: relative;
  padding-bottom: 0;
  border-bottom: 0;
}

.blog-detail .news-block-three h4 {
  margin-bottom: 20px;
}

.blog-detail .news-block-three h5 {
  position: relative;
  display: block;
  font-size: 20px;
  line-height: 1.2em;
  color: #ffaa00;
  font-weight: 700;
  margin-bottom: 20px;
}

.blog-detail .news-block-three p {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 30px;
  color: var(--black-shade7);
  font-weight: 400;
  margin-bottom: 20px;
}

.blog-detail .news-block-three .lower-content {
  padding-top: 10px;
}

.blog-detail .news-block-three .lower-content .info {
  border-bottom: 1px solid #dddddd;
  margin-bottom: 20px;
}

.blog-detail .post-images {
  position: relative;
  margin-bottom: 10px;
  margin-top: 60px;
}

.blog-detail .post-images .image {
  margin-bottom: 30px;
}

.blog-detail .post-images .image img {
  display: block;
  width: 100%;
  height: auto;
}

.blog-detail blockquote {
  position: relative;
  max-width: 700px;
  width: 100%;
  margin: 0 auto 50px;
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.19);
  padding: 35px 35px 25px;
}

.blog-detail blockquote .icon {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 20px;
  color: var(--black-shade7);
  font-weight: 400;
  margin-bottom: 10px;
}

.blog-detail blockquote cite {
  position: relative;
  float: right;
  font-size: 18px;
  line-height: 1em;
  color: var(--black-shade2);
  display: inline-block;
  font-weight: 700;
  font-style: normal;
  font-family: var(--heading-family);
  padding-left: 10px;
}

.blog-detail blockquote cite:before {
  position: absolute;
  top: 8px;
  right: 100%;
  height: 3px;
  width: 37px;
  background-color: #ffaa00;
  content: "";
}

/*post share options*/

.blog-detail .post-share-options {
  position: relative;
  margin-bottom: 30px;
}

.blog-detail .post-share-options .icon {
  position: relative;
  float: left;
  height: 50px;
  width: 50px;
  background-color: #ebebeb;
  text-align: center;
  font-size: 18px;
  color: var(--black-shade2);
  line-height: 50px;
  border-radius: 50%;
}

.blog-detail .post-share-options .tags {
  position: relative;
  float: left;
  padding: 8px 25px;
}

.blog-detail .post-share-options .tags li {
  position: relative;
  display: inline-block;
}

.blog-detail .post-share-options .tags .a {
  position: relative;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  color: var(--black-shade7);
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.blog-detail .post-share-options .tags .a:hover {
  color: var(--primary-color);
}

.blog-detail .post-share-options .social-icon {
  position: relative;
  float: left;
  margin: 0;
  list-style-type: none;
  padding: 10px 13px;
}

.blog-detail .post-share-options .social-icon li {
  position: relative;
  float: left;
  margin: 0 13px;
}

.blog-detail .post-share-options .social-icon li .a {
  position: relative;
  display: block;
  font-size: 15px;
  font-weight: 400;
  line-height: 30px;
  color: var(--black-shade7);
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.blog-detail .post-share-options .social-icon li .a:hover {
  color: var(--primary-color);
}