.project-section {
  position: relative;
  padding: 100px 0 0;
}

.project-section .layer-image {
  position: absolute;
  left: 50%;
  bottom: 0;
  margin-left: -960px;
  background-color: var(--black-shade0);
}

.project-section .layer-image img {
  display: block;
  max-width: 100%;
  height: auto;
  opacity: 0.2;
}

.project-section .project-carousel {
  position: relative;
}

.project-block {
  position: relative;
  margin-bottom: 40px;
}

.project-block .inner-box {
  position: relative;
  padding-right: 100px;
  padding-bottom: 110px;
}

.project-block .image-box {
  position: relative;
}

.project-block .image-box .image {
  position: relative;
  margin-bottom: 0;
}

.project-block .image-box .image img {
  display: block;
  width: 100%;
  height: auto;
}

.project-block .content-box {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 45px 45px 50px;
  max-width: 400px;
  width: 100%;
  background-color: var(--black-shade0);
  background-image: url(../../assets/images/icons/pattern-2.jpg);
  background-repeat: repeat;
  background-position: center;
  opacity: 0;
  -webkit-transform: translateY(-20%);
  -moz-transform: translateY(-20%);
  -ms-transform: translateY(-20%);
  -o-transform: translateY(-20%);
  transform: translateY(-20%);
  -webkit-transition: all 700ms ease;
  -moz-transition: all 700ms ease;
  -ms-transition: all 700ms ease;
  -o-transition: all 700ms ease;
  transition: all 700ms ease;
}

.active .project-block .content-box {
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 400ms;
  -moz-transition-delay: 400ms;
  -ms-transition-delay: 400ms;
  -o-transition-delay: 400ms;
  transition-delay: 400ms;
}

.project-block .content-box h4 {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 1.2em;
  color: var(--primary-color);
  font-weight: 700;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.project-block .content-box h4 .a {
  display: inline-block;
  color: var(--primary-color);
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.project-block .content-box h4 .a:hover {
  color: var(--bg-color);
}

.project-block .content-box h4:before {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 1px;
  width: 50px;
  background-color: var(--bg-color);
  content: "";
}

.project-block .content-box .text {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 30px;
  color: var(--bg-color);
  font-weight: 400;
  margin-bottom: 20px;
}

.project-block .content-box .read-more {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  color: var(--primary-color);
  font-weight: 400;
  text-decoration: underline;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.project-block .content-box .read-more:hover {
  color: var(--bg-color);
}

.project-carousel .owl-dots {
  position: absolute;
  left: 50%;
  bottom: 37px;
  margin-left: -265px;
}

.project-carousel .owl-dot {
  position: relative;
  display: inline-block;
  height: 10px;
  width: 10px;
  background-color: #b7b7b7;
  border-radius: 50%;
  margin-right: 25px;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.project-carousel .owl-dot:hover,
.project-carousel .owl-dot.active {
  background-color: var(--primary-color);
}

.project-carousel .owl-nav {
  position: absolute;
  left: 50%;
  margin-left: -75px;
  bottom: 32px;
}

.project-carousel .owl-next,
.project-carousel .owl-prev {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 28px;
  color: var(--primary-color);
  font-weight: 400;
  padding: 5px 18px;
  border: 1px solid #bbbbbb;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.project-carousel .owl-next span,
.project-carousel .owl-prev span {
  color: var(--black-shade2);
  float: right;
  margin-left: 10px;
  font-size: 16px;
}

.project-carousel .owl-prev {
  display: none;
}

.project-carousel .owl-next:hover,
.project-carousel .owl-prev:hover {
  color: var(--bg-color);
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.project-section .view-more {
  position: absolute;
  left: 50%;
  bottom: 32px;
  margin-left: 55px;
  font-size: 14px;
  line-height: 28px;
  color: var(--black-shade2);
  text-align: center;
  padding: 5px 20px;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  z-index: 9;
}

.project-section .view-more:hover {
  background-color: transparent;
  color: var(--primary-color);
}

/* Theme Two */
.projects-page-section {
  position: relative;
  padding: 120px 0;
}

/*=== Sortable Masonry ===*/

.sortable-masonry .filters {
  margin-bottom: 50px;
  text-align: center;
}

.sortable-masonry .filters .filter-tabs {
  position: relative;
  display: inline-block;
  margin: 0;
  list-style-type: none;
}

.sortable-masonry .filters li {
  position: relative;
  display: inline-block;
  line-height: 20px;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  color: var(--black-shade7);
  font-weight: 400;
  margin: 0 8px 0;
  border: 1px solid transparent;
  border-radius: 5px;
  font-family: "Roboto", sans-serif;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.sortable-masonry .filters li:last-child {
  margin-right: 0;
}

.sortable-masonry .filters .filter.active,
.sortable-masonry .filters .filter:hover {
  color: var(--primary-color);
  border: 1px solid #cccccc;
}

.project-block-two {
  position: relative;
  margin-bottom: 30px;
}

.project-block-two .inner-box {
  position: relative;
}

.project-block-two .image-box {
  position: relative;
}

.project-block-two .image-box .image {
  position: relative;
  margin-bottom: 0;
}

.project-block-two .image-box .image img {
  display: block;
  width: 100%;
  height: 100%;
}

.project-block-two .overlay-box {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(34, 34, 34, 0.9);
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0.7);
  -moz-transform: scale(0.7);
  -ms-transform: scale(0.7);
  -o-transform: scale(0.7);
  transform: scale(0.7);
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.project-block-two .inner-box:hover .overlay-box {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.project-block-two .upper-box {
  position: absolute;
  right: 0;
  top: 28px;
  text-align: right;
  padding: 0 30px;
}

.project-block-two .upper-box h4 {
  position: relative;
  display: block;
  font-size: 20px;
  line-height: 1.2em;
  color: var(--bg-color);
  font-weight: 700;
  margin-bottom: 5px;
}

.project-block-two .upper-box h4 .a {
  color: var(--bg-color);
  display: inline-block;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.project-block-two .upper-box h4 .a:hover {
  color: var(--primary-color);
}

.project-block-two .upper-box .category {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 24px;
  color: var(--primary-color);
}

.project-block-two .link {
  position: absolute;
  left: 30px;
  bottom: 33px;
  font-size: 16px;
  line-height: 26px;
  color: var(--bg-color);
  font-weight: 400;
  padding-bottom: 7px;
}

.project-block-two .link:before {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 1px;
  width: 60%;
  background-color: var(--primary-color);
  content: "";
}

.projects-page-section .btn-box {
  position: relative;
  margin-top: 50px;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

th.table_key {
  width: 15%;
}

th.table_value {
  width: 85%;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.return {
  display: block;
  text-align: end;
  font-size: 1rem;
  padding: 10px;
  /* position: absolute; */
  /* right: 0; */
}