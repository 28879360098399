.team-section {
  position: relative;
  padding: 100px 0;
}

.team-section:before {
  position: absolute;
  left: 50%;
  top: 0;
  height: 430px;
  width: 100%;
  background-color: #f5f5f5;
  content: "";
  -webkit-transform: translateX(-375px);
  -moz-transform: translateX(-375px);
  -ms-transform: translateX(-375px);
  -o-transform: translateX(-375px);
  transform: translateX(-375px);
}

.team-section .team-carousel {
  position: relative;
  padding-bottom: 110px;
}

.team-block {
  position: relative;
  margin: 0 10px;
}

.team-block .inner-box {
  position: relative;
}

.team-block .image-box {
  position: relative;
  overflow: hidden;
}

.team-block .image-box .image {
  position: relative;
  margin-bottom: 0;
  border-radius: 0 0 20px 20px;
  overflow: hidden;
}

.team-block .image-box .image img {
  display: block;
}

.team-block .social-links {
  position: absolute;
  right: -100%;
  bottom: 0;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 0 0 0 20px;
  padding: 0 15px;
  opacity: 0;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.team-block .inner-box:hover .social-links {
  opacity: 1;
  right: 0;
}

.team-block .social-links li {
  position: relative;
  list-style-type: none;
  float: left;
  margin-left: 5px;
}

.team-block .social-links li:first-child {
  margin-left: 0;
}

.team-block .social-links li a {
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  color: var(--bg-color);
  padding: 15px 0;
  width: 40px;
  text-align: center;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.team-block .social-links li a:hover {
  background-color: var(--primary-color);
}

.team-block .caption-box {
  position: relative;
  text-align: center;
  padding: 33px 0 0;
}

.team-block .caption-box .name {
  display: block;
  font-size: 20px;
  line-height: 1.2em;
  color: var(--black-shade2);
  font-weight: 700;
  margin-bottom: 15px;
}

.team-block .caption-box .name a {
  color: var(--black-shade2);
  display: inline-block;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.team-block .caption-box .name a:hover {
  color: var(--primary-color);
}

.team-block .caption-box .description {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 1.2em;
  color: var(--black-shade7);
  font-weight: 400;
}

.team-carousel .owl-nav {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
}

.team-carousel .owl-next,
.team-carousel .owl-prev {
  position: relative;
  display: inline-block;
  height: 35px;
  width: 35px;
  text-align: center;
  border: 1px solid #404040;
  color: var(--primary-color);
  font-size: 20px;
  line-height: 33px;
  margin: 0 12px;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.team-carousel .owl-next .fa,
.team-carousel .owl-prev .fa {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.team-carousel .owl-next:hover,
.team-carousel .owl-prev:hover {
  color: #404040;
  border-color: var(--primary-color);
}

.team-section.alternate {
  padding: 120px 0 90px;
}

.team-section.alternate .team-block {
  position: relative;
  margin-bottom: 30px;
}
