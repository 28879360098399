/* Theme Color */

.main-footer.style-two .social-icon-two li a:hover,
.news-block-three .inner-box .info li:hover i,
.news-block-three .inner-box .info li a:hover,
.news-block-three .blockquote .author,
.news-block-three .inner-box h4 a:hover,
.service-detail .accordion-box .block.active-block .icon:before,
.main-footer.style-two .footer-bottom .copyright-text a,
.main-footer.style-two .newslatter-form .form-group input[type="submit"]:hover,
.main-footer.style-two .newslatter-form .form-group button:hover,
.news-section-two .view-all,
.news-block-two .content-column h3 a,
.call-to-action .title-box .text span,
.call-to-action .title-box h4,
.sortable-masonry .filters .filter.active,
.sortable-masonry .filters .filter:hover,
.coming-soon .copyright-text a,
.coming-soon .content .content-inner h2 span,
.comments-area .comment-box .reply-btn,
.main-footer.style-three .user-links li a:hover,
.main-footer.style-three .recent-posts .post h4 a:hover,
.main-footer.style-two .serivce-list li a:hover,
.main-footer.style-two .user-links li a:hover,
.main-footer.style-two .recent-posts .post h4 a:hover,
.main-footer.style-two .about-widget .text p strong,
.contact-info-section.style-three .contact-info-block h3,
.contact-info-section.style-three .title-column h2 span,
.contact-info-block h3,
.blog-post-controls h5 a:hover,
.blog-detail .post-share-options .social-icon li a:hover,
.blog-detail .post-share-options .tags a:hover,
.news-block-three .blockquote h2 span,
.blog-detail .news-block-three h5,
.news-block-three .lower-content h4 a:hover,
.news-block-three .lower-content .info li:hover i,
.news-block-three .lower-content .info li a:hover,
.tag-list li a:hover,
.latest-news .recent-post:hover h3 a,
.cat-list li.active a,
.cat-list li a:hover,
.news-block .lower-content .link-box a,
.news-block .lower-content h3 a:hover,
.faqs-section.style-two .accordion-box .block .acc-btn.active .icon:before,
.accordion-box .block .icon,
.accordion-box .block .acc-btn.active,
.process-block .title,
.process-block h4 a:hover,
.process-block .icon-box .icon,
.fluid-section-one .content-column .inner-box .choose-info li:hover a,
.fluid-section-one .content-column .inner-box .choose-info li:hover .icon,
.fluid-section-one .content-column .inner-box .choose-info li .icon,
.testimonial-carousel .owl-next,
.testimonial-carousel .owl-prev,
.testimonial-block .info-box .designation,
.team-detail .social-links li a:hover,
.team-detail .social-links li.title,
.team-detail .contact-list li .icon,
.team-detail .content-column .designation,
.team-carousel .owl-next,
.team-carousel .owl-prev,
.team-block .caption-box .name a:hover,
.load-more-option .load-more a,
.project-detail .file-list li a:hover,
.project-detail .file-list li .icon,
.video-box .link .icon,
.project-detail .project-info li .icon,
.project-detail .project-info li a:hover,
.project-block-two .upper-box .category,
.project-block-two .upper-box h4 a:hover,
.feature-block.block-three .icon,
.project-section .view-more:hover,
.project-carousel .owl-next,
.project-carousel .owl-prev,
.project-block .content-box .read-more,
.project-block .content-box h4 a,
.project-block .content-box h4,
.fun-fact-section.style-two .count-box:hover .icon,
.fun-fact-section.style-two .count-box .count-text,
.fun-fact-section .count-box .icon,
.brochure-box > span,
.services--list li:hover a:before,
.services--list li.active a:before,
.service-tabs .tab-btns .tab-btn:hover,
.service-tabs .tab-btns .tab-btn.active-btn,
.service-block-three .icon-box .icon,
.service-block-two h5 a:hover,
.service-block-two .icon-box .icon,
.services-carousel .owl-next,
.services-carousel .owl-prev,
.service-block .inner-box:hover .caption-box .icon,
.service-block .inner-box:hover .caption-box h4 a,
.about-section .blockquote cite,
.about-section .content-column .message span,
.about-section .content-column .title,
.video-section .title-box .text span,
.video-section .title-box h4,
.welcome-section .title-column h2,
.main-slider.style-two h2 span,
.main-slider h2 span,
.page-title .bread-crumb li a:hover,
.page-title .bread-crumb li:before,
.page-title .bread-crumb li,
.sec-title .title,
.sticky-header .main-menu .navigation > li:hover > a,
.sticky-header .main-menu .navigation > li.current > a,
.sticky-header .main-menu .navigation > li.current-menu-item > a,
.header-style-two .outer-box .info-box a:hover,
.main-menu .navigation > li > ul > li > ul > li > a:hover,
.main-header .search-panel .search-btn:hover,
.main-menu .navigation > li > ul > li:hover > a,
.main-header .info-box li a:hover,
.user-links li a:hover,
.serivce-list li a:hover,
.recent-posts .post h4 a:hover,
.social-icon-two li a:hover,
.header-top .change-language .language > a,
.list-style-two li:before,
.social-icon-one li a:hover,
.list-style-one li:before,
.btn-style-four:hover,
.btn-style-three .icon,
.btn-style-two span,
.btn-style-one .icon,
.theme_color,
a {
  color: var(--primary-color);
}

/*=== Background Color ===*/
.news-block-three .image-box .icon-box,
.main-footer.style-two.alternate .footer-bottom,
.news-section-two .owl-next:hover,
.news-section-two .owl-prev:hover,
.services-sidebar .brochure-link:hover,
.main-footer .footer-bottom,
.news-block-three .lower-content .link-box a:hover,
.news-block .inner-box:hover .lower-content,
.main-footer.style-two .newslatter-form .form-group input[type="submit"],
.main-footer.style-two .newslatter-form .form-group button,
.call-to-action .btn-box a:hover,
.project-detail .single-item-carousel .owl-next:hover,
.project-detail .single-item-carousel .owl-prev:hover,
.contact-info-section.style-two,
.contact-info-section .title-column h2:before,
.styled-pagination li.next a:hover,
.styled-pagination li.prev a:hover,
.styled-pagination li a:hover,
.styled-pagination li.active a,
.news-block-three .image-box:hover .icon-box,
.blog-detail blockquote cite:before,
.news-section .form-column .inner-column,
.news-block .image-box:hover .icon-box,
.call-back-section:before,
.process-block .count:before,
.faq-form-section:before,
.process-section.alternate .process-block .inner-box:hover .icon-box,
.testimonial-section .form-column .inner-column:before,
.testimonial-section .layer-image:before,
.team-block .social-links li a:hover,
.load-more-option li.prev a:hover,
.load-more-option li.next a:hover,
.video-box .link:hover .icon,
.project-block-two .link:before,
.video-section .video-box .image,
.feature-block.block-two .inner-box,
.sidebar .search-box .form-group button,
.project-section .view-more,
.project-carousel .owl-next:hover,
.project-carousel .owl-prev:hover,
.project-carousel .owl-dot:hover,
.project-carousel .owl-dot.active,
.brochure-box .theme-btn:hover,
.main-footer .footer-column .widget-title:before,
.contact-info-section.style-three .title-column h2:before,
.service-block-three .inner-box:hover,
.service-block .caption-box .icon,
.service-block .overlay-box:before,
.about-section .image-column .image-box .link,
.main-header .main-box:before,
.header-top .change-language .language .dropdown-menu > li > a:hover,
.scroll-to-top:hover,
.btn-style-four {
  background-color: var(--primary-color);
}

/*Border Color*/
.contact-form .form-group input:focus,
.contact-form .form-group select:focus,
.contact-form .form-group textarea:focus,
.about-section .blockquote,
.comment-form .form-group input:focus,
.comment-form .form-group select:focus,
.comment-form .form-group textarea:focus,
.sidebar .search-box .form-group input:focus,
.styled-pagination li.next a:hover,
.styled-pagination li.prev a:hover,
.styled-pagination li a:hover,
.styled-pagination li.active a,
.news-section-two .slick-dots li:hover,
.news-section-two .slick-dots li.slick-active,
.newslatter-form .form-group input:focus,
.newslatter-form .form-group select:focus,
.newslatter-form .form-group textarea:focus,
.request-form .form-group input:focus,
.request-form .form-group select:focus,
.request-form .form-group textarea:focus,
.testimonial-carousel .owl-next:hover,
.testimonial-carousel .owl-prev:hover,
.team-carousel .owl-next:hover,
.team-carousel .owl-prev:hover,
.project-section .view-more,
.project-carousel .owl-next:hover,
.project-carousel .owl-prev:hover,
.brochure-box .theme-btn:hover,
.services--list li.active a:after,
.services--list li:hover a:after,
.service-block-three .inner-box:hover,
.service-block .caption-box,
.page-title .inner-container,
.header-style-two .search-box-btn,
.main-header .search-panel input:focus,
.main-header .search-panel select:focus,
.main-header .search-box-outer .dropdown-menu,
.main-menu .navigation > li > ul > li > ul,
.main-menu .navigation > li > ul,
.news-block-three .lower-content .link-box a,
.header-top .change-language .language .dropdown-menu,
.btn-style-four {
  border-color: var(--primary-color);
}

.time-counter .time-countdown .counter-column:before {
  border-top-color: var(--primary-color);
}

.services--list li.active a,
.services--list li:hover a {
  border-right-color: var(--primary-color);
}

.news-block-three .image-box .icon-box:before {
  border-bottom-color: var(--primary-color);
}

@media only screen and (max-width: 767px) {
  .main-menu .navbar-header .navbar-toggler .icon {
    color: var(--primary-color);
  }

  .main-menu .navbar-header .navbar-toggle,
  .main-menu .navbar-collapse > .navigation,
  .main-menu .navbar-collapse > .navigation > li > ul,
  .main-menu .navbar-collapse > .navigation > li > ul > li > ul,
  .main-menu .navbar-collapse > .navigation > li > a,
  .main-menu .navbar-collapse > .navigation > li > ul > li > a,
  .main-menu .navbar-collapse > .navigation > li > ul > li > ul > li > a,
  .main-menu .navbar-collapse > .navigation > li > a:hover,
  .main-menu .navbar-collapse > .navigation > li > a:active,
  .main-menu .navbar-collapse > .navigation > li > a:focus,
  .main-menu .navbar-collapse > .navigation > li:hover > a,
  .main-menu .navbar-collapse > .navigation > li > ul > li:hover > a,
  .main-menu .navbar-collapse > .navigation > li > ul > li > ul > li:hover > a,
  .main-menu .navbar-collapse > .navigation > li.current > a,
  .main-menu .navbar-collapse > .navigation > li.current-menu-item > a {
    background-color: var(--primary-color) !important;
  }
}
