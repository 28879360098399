.call-back-section {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 95px 0 70px;
  background-color: #f5f5f5;
}

.call-back-section.alternate {
  background-color: var(--bg-color);
}

.call-back-section:before {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 32%;
  background-color: var(--primary-color);
  border-radius: 0 0 0 150px;
  content: "";
}

.call-back-section .form-column {
  position: relative;
  margin-bottom: 50px;
}

.call-back-section .form-column .inner-column {
  position: relative;
  padding-top: 20px;
}

.call-back-section .sec-title {
  margin-bottom: 50px;
}

.call-back-section .form-group {
  position: relative;
  margin-bottom: 30px;
}

.call-back-section .form-group:last-child {
  margin-bottom: 0;
}

.request-form .form-group input[type="text"],
.request-form .form-group input[type="email"],
.request-form .form-group input[type="url"],
.request-form .form-group textarea,
.request-form .form-group select {
  position: relative;
  display: block;
  width: 100%;
  font-size: 14px;
  color: var(--black-shade2);
  line-height: 20px;
  padding: 15px 28px;
  border: 1px solid var(--white-color);
  background-color: var(--bg-color);
  font-weight: 400;
  height: 50px;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.request-form .form-group input:focus,
.request-form .form-group select:focus,
.request-form .form-group textarea:focus {
  border-color: var(--primary-color);
}

.request-form .form-group textarea {
  height: 200px;
  resize: none;
}

.request-form .form-group button {
  text-transform: capitalize;
  font-size: 14px;
  padding: 15px 45px;
  line-height: 20px;
}

.call-back-section .image-column {
  position: relative;
  margin-bottom: 50px;
}

.call-back-section .image-column .inner-column {
  position: relative;
  margin-right: -23px;
}

.call-back-section .image-column .image {
  position: relative;
  margin-left: 22px;
  z-index: 2;
}

.call-back-section .image-column .image:before {
  position: absolute;
  left: -22px;
  top: 22px;
  height: 100%;
  width: 100%;
  background-color: var(--bg-color);
  border: 2px solid #a1a1a1;
  content: "";
  z-index: -1;
}

.call-back-section .image-column .image img {
  width: 100%;
  height: auto;
  display: inline-block;
}
