.sticky-header {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  left: 0px;
  top: 0px;
  width: 100%;
  padding: 0px 0px;
  z-index: 99999;
  background: var(--bg-color);
  border-bottom: 1px solid #f1f1f1;
}

.fixed-header {
  opacity: 1;
  z-index: 9999;
  visibility: visible;
}

.fixed-header .logo {
  padding: 10px 0;
}

.sticky-header .main-menu .navigation > li {
  margin-left: 50px;
  padding: 0 !important;
  margin-right: 0 !important;
  background-color: transparent;
  list-style-type: none;
}

.sticky-header .main-menu .navigation > li:after {
  display: none;
}

.sticky-header .main-menu .navigation > li > .a {
  position: relative;
  display: block;
  padding: 20px 0px;
  line-height: 30px;
  font-size: 16px;
  font-weight: 400;
  color: var(--black-shade2);
  border: 0;
  text-transform: capitalize;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.sticky-header .main-menu .navigation > li:hover > .a,
.sticky-header .main-menu .navigation > li .current,
.sticky-header .main-menu .navigation > li.current-menu-item > .a {
  opacity: 1;
  color: var(--primary-color);
  background-color: var(--bg-color);
}

.sticky-header .main-menu .navigation > li:before,
.sticky-header .main-menu .navigation > li.dropdown > .a:after {
  display: none;
}

.sticky-header .main-menu .navigation > li.dropdown:hover > ul {
  margin-top: 0;
}

.sticky-header .auto-container {
  max-width: 1200px;
}
