@import url("https://fonts.googleapis.com/css?family=Raleway:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i|Roboto:300,300i,400,400i,500,500i,700,700i,900,900i|Oswald:200,300,400,500,600,700&amp;display=swap");
/*
	font-family: 'Roboto', sans-serif;
	font-family: 'Raleway', sans-serif;
	font-family: 'Oswald', sans-serif;
*/

* {
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
  font-size: 100%;
}

:root {
  --bg-color: #ffffff;
  --text-color: #0134BF;
  --primary-color: #0134BF;
  --primary-color2: #0134BF;
  --primary-color3: #0134BF;
  --black-shade0: #000000;
  --black-shade1: #111111;
  --black-shade2: #222222;
  --black-shade3: #0134BF;
  --black-shade5: #0134BF;
  --black-shade6: #0134BF;
  --black-shade7: #0134BF;
  --theme-color: #25262c;
  --white-color: #dddddd;
  --shadow-color: rgba(0, 0, 0, 0.1);
  --heading-family: "Raleway", sans-serif;
}

body {
  font-size: 14px;
  color: var(--text-color);
  line-height: 28px;
  font-weight: 400;
  background: var(--bg-color);
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-family: "Roboto", sans-serif;
}
